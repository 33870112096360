import React from "react";
import { Link } from "gatsby";
import { Container } from 'reactstrap';

import css from './header.module.css';
import logo from '../img/template/wuxing_logo.svg';
import logoNapis from '../img/template/wuxing_text.svg';

export default props => (
  <header>
    <Container>
      <div className={css.container}>
        <Link to="/">
          <img src={logo} alt="logo" className={css.logo} />
          <img src={logoNapis} alt="wuxing" className={css.text} />
        </Link>
      </div>
    </Container>
  </header>
)
