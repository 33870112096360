import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { Navbar, Nav, Collapse, NavItem, NavbarToggler, NavLink, Container } from "reactstrap";
import css from './navbar.module.css';

import Header from './Header';

const Navigationbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen })

  render() {
    return (
      <StaticQuery
        query={graphql`
          query NavLinks {
            site {
              siteMetadata {
                menuLinks {
                  name
                  link
                  sublinks {
                    name
                    link
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <React.Fragment>
            <Header />
            <Navbar expand="md" className={css.navbar} dark>
              <NavbarToggler onClick={this.toggle} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Container>
                  <Nav className={css.nav} navbar>
                    {data.site.siteMetadata.menuLinks.map((link, i) => (
                      <NavItem key={i}>
                        <Link to={link.link}>{link.name}</Link>
                        <div className={css.dropdown}>
                          {link.sublinks && link.sublinks.length > 0 && link.sublinks.map((subLink, l) => (
                            <Link to={subLink.link} key={l}>{subLink.name}</Link>
                          ))}
                        </div>
                      </NavItem>
                    ))}
                  </Nav>
                </Container>
              </Collapse>
            </Navbar>
          </React.Fragment>
        )}
      />
    );
  }
};

export default Navigationbar;
